<template>
    <div>
        <Breadcrumb titulo="MENSAGENS" :items="itensBreadcrumb" backgroundColor="#f2f932" color="#000" />
        <div class="container-fluid py-4" style="background-color: #e9e9e9; margin-top: 15px; width: 98%; font-size: 12px !important;">
            <b-row>
                <b-col>
                    <table style="width:100%; display: table">
                        <tr>
                            <td style="max-width: 15%; width: 15%;">Data inicial:</td>
                            <td><input-data-tabela v-model="dataInicial" placeholder="Data inicial" style="margin: 0px 0px 0px -30px; " /></td>
                            <td style="max-width: 15%; width: 15%;">Data Final:</td>
                            <td><input-data-tabela v-model="dataFinal" placeholder="Data final" style="margin: 0px 0px 0px -30px; " /></td>
                        </tr>
                    </table>
                </b-col>
                <b-col style="text-align: end;">
                    <i class="far fa-question-circle"
                       v-b-tooltip.hover
                       title="A consulta pode demorar, por favor aguarde"
                       style="text-align:left; margin-right: 1vh;"></i>
                    <input type="text" class="input_search_text" placeholder="Consulta mensagem" v-model="texto_filtragem" />

                    <span class="btn_filtrar_mensagem" id="btn_filtrar_mensagem" @click="filtar_mensagem()">Filtrar</span>
                </b-col>
            </b-row>

            <div class="row my-2">
                <div class="col-sm bg-light px-1" style="margin: 0 15px; font-size: 12px;">
                    <div class="segura_tabela_modulos">
                        <table class="table b-table table-striped table-sm tabela_listagem_campanha">
                            <thead>
                                <tr style="background-color: white;">
                                    <th>Informações</th>
                                    <th>Codigo</th>
                                    <th>Data inclusão</th>
                                    <th>Origem</th>
                                    <th>Mensagem</th>

                                </tr>
                            </thead>
                            <tr v-for="(item, index) in listaConversa.List" :class="computedClass(index)">
                                <td>
                                    <div class="abrir_conversa_padrao" v-on:click='abrirConversa(item.AtendimentoId,item.Codigo, item.ClienteId)'>
                                        <i class='fas fa-info-circle fa-2x conversa_funil'></i>
                                    </div>
                                </td>
                                <td><span class="item_lista_campanha">{{item.Codigo}}</span></td>
                                <td>{{item.Inclusao}}</td>
                                <td>{{item.Origem}}</td>
                                <td>{{item.Mensagem.substring(0, 50)}} {{item.Mensagem.substring(0, 50).length  === 50 ? '...': ''}}</td>
                            </tr>
                        </table>
                    </div>
                </div>

            </div>
            <b-row id="rodape_consulta_mensagem">
                <b-col>
                    <span :class="current_page <= 1 ? 'desativar-botao-paginacao' : ''" class="next_before" @click="filtar_mensagem('ant')"><</span>

                    {{current_page}} de {{qnt_paginas}}

                    <span :class="current_page >= qnt_paginas ? 'desativar-botao-paginacao' : ''" class="next_before" @click="filtar_mensagem('prox')">></span>
                </b-col>
            </b-row>
            <!--<div class="row">
                <div class="col-12">

                    <tabela-mensagens
                        class="consulta-mensagens-page-tabela"
                        :items="mensagensConsulta"
                        :itemsExportacao="mensagensExportacao"
                        :loading="loadingTabela"
                        :totalDeResultados="totalDeResultados"
                        :filtrosAplicados="filtrosIniciais"
                        :enumOrigem="enumOrigem"
                        @buscar="buscarComFiltros"
                        @exportar="buscarComFiltrosExportacao"
                    />

                </div>
            </div>-->
        </div>
        <ModalConversaPadrao ref="modal_coversa_padrao"
                             :dadosConversa="dadosConversa" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    //import TabelaMensagens from '@/components/tabelas/TabelaMensagens.vue'
    //import { mapActions, mapGetters } from 'vuex'
    //import AtendimentoServices from '@/assets/js/services/AtendimentoServices'


    import ModalConversaPadrao from '@/components/consultas/ModalCoversaPadrao.vue'
    import RelatoriosServices from '@/assets/js/services/RelatoriosConsultasServices'
    import InputDataTabela from '@/components/inputs/InputDataTabela.vue'
    export default {
        name: 'ConsultaMensagensPage',

        components: {
            Breadcrumb,
            ModalConversaPadrao,
            InputDataTabela
        },

        data: function () {
            return {
                itensBreadcrumb: [
                    {
                        id: '1',
                        classe: 'fas fa-search',
                        texto: 'Consultas',
                        link: '/consultas'
                    },
                    {
                        id: '2',
						classe: 'fas fa-comment-alt',
                        texto: 'Consultas mensagens',
                        link: ''
                    },
                ],
                loadingTabela: false,
                mensagensConsulta: [],
                mensagensExportacao: [],
                filtrosIniciais: null,
                totalDeResultados: 0,
                listaConversa: [],
                dadosConversa: {},
                dataInicial: (new Date()).toLocaleDateString().split('/').reverse().join('-'),
                dataFinal: (new Date()).toLocaleDateString().split('/').reverse().join('-'),
                pagina: 0,
                texto_filtragem: null,
                qnt_paginas: 1,
                current_page: 1
            }
        },

        created() {
            this.filtar_mensagem();
        },

        methods: {
            carregaConversaV2: async function () {
                $("#caixa_carregamento").show()
                this.listaConversa = await RelatoriosServices.carregaConversaV2();
                $("#caixa_carregamento").hide()
            },
            computedClass(index) {
                if (index % 2 != 0) {
                    return "linha_par"
                }
            },
            abrirConversa: function (id, protocolo, clienteId) {
                const valor = { Id: id, Protocolo: protocolo, ClienteId: clienteId }
                this.dadosConversa = valor;
                this.$refs.modal_coversa_padrao.show()
            },
            filtar_mensagem: async function (acao) {

                if (acao == 'prox') {
                    this.pagina += 10

                }
                if (acao == 'ant') {
                    this.pagina - 1 <= 0 ? this.pagina = 1 : this.pagina -= 10

                }


                var dados = "";
                if (this.texto_filtragem != null) {
                    dados += "Mensagem=" + this.texto_filtragem + "&";
                }
                dados += "Limit=10&DataInicial=" + this.dataInicial + "&DataFinal=" + this.dataFinal + "&Offset=" + this.pagina + "&Sort=-Inclusao"
                $("#caixa_carregamento").show()
                this.listaConversa = await RelatoriosServices.carregaConversaV2(dados);
                $("#caixa_carregamento").hide()
                if (this.listaConversa.List.length > 0) {
                    this.current_page = ((this.listaConversa.CurrentPage / 10) + 1)
                    this.qnt_paginas = Math.ceil((this.listaConversa.TotalResults / 10));

                }
            }
        }
    }
</script>

<style scoped>
    .consulta-mensagens-page-tabela {
        height: calc(100vh - 180px);
    }

    .linha_par {
        background-color: rgb(233, 233, 233);;
    }

    .menu_consulta_conversa {
        margin-left: 0px;
    }

    .input_data {
        display: inline-flex;
        width: 20%;
        margin-right: 15px;
    }

    .input_search_text {
        background-color: white;
        color: var(--cinza-5);
        width: 60%;
        border: 1px solid var(--cinza-4);
        outline: none;
        margin-right: 3vh;
        height: 100%
    }

    .btn_filtrar_mensagem {
        cursor: pointer;
        width: 118px;
        text-align: center;
        padding: 8px;
        position: relative;
        background-color: #39d325;
        color: #FFF;
    }

    .desativar-botao-paginacao {
        display: none;
    }

    .next_before {
        padding: 6px;
        cursor: pointer;
        font-weight: bold;
        font-size: 18px;
    }

    .data_label {
        margin: 0;
        padding-right: 3px;
        font-size: 13px;
        width: 10%;
        align-self: center;
    }

    .ajuste-tabela-toda {
        background-color: #e8e8e8;
        margin-top: 10px;
        width: 98% !important;
    }

    .abrir_conversa_padrao {
        cursor: pointer;
    }

    .segura_tabela_modulos {
        height: calc(100vh - 280px);
        overflow-y: auto;
    }
</style>